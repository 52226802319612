import { AppstoreOutlined, TableOutlined } from '@ant-design/icons'
import {
  Alert,
  Card,
  Col,
  Divider,
  Pagination,
  Radio,
  Spin,
  Table,
  Tag,
  Typography,
} from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import TicketsApi from '../../api/ticketApi'
import {
  createTicketLink,
  formatNumber,
  formatPrice,
  prepareTicketComment,
  prepareTicketDate,
  renderColoredValue,
  restOfTicketsToPolishString,
} from '../common/Helpers'
import TicketFilters from '../common/TicketFilters'

const StyledTable = styled(Table)`
  table thead tr th {
    background: #f1f1f1 !important;
  }
  table thead tr th ant-table-column-has-sorters {
    background: #f0f0f0;
  }
  table tr:nth-child(even) {
    background: #fafafa;
  }
  th.column-comment,
  td.column-comment {
    text-align: center !important;
    padding: 15px 10px;
  }
`

const StyledPagination = styled(Pagination)`
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    padding-inline-end: 18px;
  }
  .ant-select-arrow {
    margin-top: -6px;
  }
`

const TicketsPage = () => {
  const [tickets, setTickets] = useState(null)
  const [lastUpdateDate, setLastUpdateDate] = useState(null)
  const [error, setError] = useState(null)
  const [isTableLoading, setIsTableLoading] = useState(true)
  const [selectedView, setSelectedView] = useState('tiles')
  const [request, setRequest] = useState({
    currentPage: 1,
    pageSize: 12,
    price: '',
    name: '',
    hideWithoutMainPrize: false,
    sortDir: 'descend',
    sortCol: 'investmentReturn',
  })

  const { Title, Text } = Typography

  const handleTableChange = useCallback(
    (pagination, _, sorter) => {
      setRequest({
        ...request,
        currentPage: pagination.current,
        pageSize: pagination.pageSize,
        sortDir: sorter.order,
        sortCol: sorter.order && sorter.field,
      })
    },
    [request],
  )

  const getTickets = useCallback(async (request) => {
    setIsTableLoading(true)
    setError(null)

    try {
      const result = await TicketsApi.getTicketsFromApi({
        ...request,
        currentPage: request.currentPage - 1,
      })
      setTickets(result)
    } catch (e) {
      setError(e.json)
    }
    setIsTableLoading(false)
  }, [])

  const getLastUpdateDate = useCallback(async () => {
    setLastUpdateDate(await TicketsApi.getLastUpdateDate())
  }, [])

  useEffect(() => {
    getLastUpdateDate()
  }, [getLastUpdateDate])

  useEffect(() => {
    getTickets(request)
  }, [request, getTickets])

  const handleFilterChange = useCallback(
    (filterName, value) => {
      setRequest({
        ...request,
        currentPage: 1,
        [filterName]: value,
      })
    },
    [request],
  )

  const handleCurrentPageChanged = useCallback(
    (newPage, newPageSize) => {
      setRequest({
        ...request,
        currentPage: newPage,
        pageSize: newPageSize,
      })
    },
    [request],
  )

  const columns = useMemo(
    () => [
      {
        title: 'Nazwa',
        className: 'column-comment',
        dataIndex: 'name',
        render: (name, row) => (
          <Link to={createTicketLink(name, row.number)}>
            {name} [{row.number}]
          </Link>
        ),
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        width: 280,
      },
      {
        title: 'Cena',
        className: 'column-comment',
        dataIndex: 'price',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text) => `${formatPrice(text)}`,
        width: 95,
      },
      {
        title: 'Główna wygrana',
        className: 'column-comment',
        dataIndex: 'biggestPrizeValue',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text) => `${formatPrice(text)}`,
        width: 120,
      },
      {
        title: 'Pozostałe główne wygrane',
        className: 'column-comment',
        dataIndex: 'topPrizesLeft',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text, row) =>
          text === 0 ? (
            <Tag color="red">Brak!</Tag>
          ) : (
            `${formatNumber(text)} z ${formatNumber(row.topPrizeInitialAmount)}`
          ),
        width: 125,
      },
      {
        title: 'Szacowany zwrot',
        dataIndex: 'investmentReturn',
        defaultFilteredValue: 'investmentReturn',
        className: 'column-comment',
        defaultSortOrder: 'descend',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text) => renderColoredValue(text),
        width: 135,
      },
      {
        title: 'Szansa na nagrodę główną',
        dataIndex: 'chanceForWinning1stDegreePrize',
        className: 'column-comment',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text) => (text === 0 ? <Tag color="red">0%</Tag> : `${text}%`),
        width: 130,
      },
      {
        title: 'Zdrapki w sprzedaży',
        children: [
          {
            title: 'Ilościowo',
            className: 'column-comment',
            dataIndex: 'estimatedTicketsLeft',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            render: (text) => `${formatNumber(text)} sztuk`,
            width: 155,
          },
          {
            title: '[%]',
            className: 'column-comment',
            dataIndex: 'estimatedTicketsLeftPercentage',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            render: (text) => `${text.toFixed(2)}%`,
            width: 85,
          },
        ],
      },
      {
        title: 'Szczegółowe informacje',
        dataIndex: 'comment',
        className: 'column-comment',
        render: (text, row) => (
          <div
            dangerouslySetInnerHTML={prepareTicketComment(
              text,
              row.startDate,
              row.endDate,
              row.topPrizesLeft,
              row.topPrizeInitialAmount,
              row.winningTicketScans,
            )}
          />
        ),
        width: 540,
      },
    ],
    [],
  )

  const renderLastUpdateDate = useCallback(() => {
    return (
      <Text
        type="secondary"
        style={{
          display: 'block',
          margin: '5px',
        }}
      >
        Data ostatniej aktualizacji:{' '}
        {prepareTicketDate(
          new Date(lastUpdateDate.lastUpdateDate)
            .toLocaleDateString()
            .replaceAll('.', ' '),
        ).toLowerCase()}
      </Text>
    )
  }, [lastUpdateDate])

  const renderTicketsTiles = useCallback(() => {
    if (!tickets) return
    if (tickets.totalItems > 0)
      return tickets.data.map(function (ticket) {
        return (
          <Link
            to={createTicketLink(ticket.name, ticket.number)}
            key={ticket.externalId}
          >
            <Card
              hoverable
              styles={{ header: { padding: '5px' }, body: { padding: '5px' } }}
              style={{
                verticalAlign: 'top',
                maxWidth: '380px',
                margin: '5px',
                display: 'inline-block',
              }}
              title={
                <img
                  style={{ maxWidth: '100%' }}
                  src={ticket.ticketLogoUrl}
                  alt="Logo zdrapki"
                  target="_self"
                />
              }
            >
              <Title style={{ fontSize: '18px' }}>
                {ticket.name} [{ticket.number}]
              </Title>
              <Text strong>
                {restOfTicketsToPolishString(ticket.topPrizesLeft)}{' '}
                {formatNumber(ticket.topPrizesLeft)} z{' '}
                {formatNumber(ticket.topPrizeInitialAmount)} nagród głównych
              </Text>
              <Divider />
              <Text>Cena: {formatPrice(ticket.price)}</Text>
              <br />
              <Text>
                Główna wygrana: {formatPrice(ticket.biggestPrizeValue)}
              </Text>
              <br />
              <Text>
                Szansa na główną: {ticket.chanceForWinning1stDegreePrize}%
              </Text>
              <br />
              <Text>
                Szacowany zwrot: {renderColoredValue(ticket.investmentReturn)}
              </Text>
              <Divider />
              <Text>
                Pozostało {formatNumber(ticket.estimatedTicketsLeft)} losów (
                {ticket.estimatedTicketsLeftPercentage.toFixed(2)}
                %)
              </Text>
              <Divider />
              <Text>
                <div
                  style={{
                    textAlign: 'center',
                    justifyContent: 'center',
                  }}
                  dangerouslySetInnerHTML={prepareTicketComment(
                    ticket.comment,
                    ticket.startDate,
                    ticket.endDate,
                    ticket.topPrizesLeft,
                    ticket.topPrizeInitialAmount,
                    ticket.winningTicketScans,
                  )}
                />
              </Text>
            </Card>
          </Link>
        )
      })
  }, [tickets])

  const renderTicketsAreLoadingAlert = useCallback(() => {
    return (
      <Title
        strong
        style={{
          fontSize: '18px',
          textAlign: 'center',
          display: 'block',
          marginBottom: '15px',
        }}
      >
        Pobieranie informacji o zdrapkach...
        <br />
        <Spin size="large" />
      </Title>
    )
  }, [])

  const handleViewChanged = useCallback(({ target: { value } }) => {
    setIsTableLoading(true)
    if (value === 'tiles') {
      setTickets(null)
      setRequest({
        currentPage: 1,
        pageSize: 12,
        price: '',
        name: '',
        hideWithoutMainPrize: false,
        sortDir: 'descend',
        sortCol: 'investmentReturn',
      })
    } else {
      setTickets(null)
      setRequest({
        currentPage: 1,
        pageSize: 10,
        price: '',
        name: '',
        hideWithoutMainPrize: false,
        sortDir: 'descend',
        sortCol: 'investmentReturn',
      })
    }
    setSelectedView(value)
  }, [])

  const renderTileComponent = useCallback(() => {
    return (
      <>
        <StyledPagination
          style={{
            marginTop: '10px',
            textAlign: 'center',
            justifyContent: 'center',
            verticalAlign: 'center',
          }}
          showSizeChanger
          onChange={handleCurrentPageChanged}
          current={request.currentPage}
          total={tickets ? tickets.totalItems : 0}
          pageSize={request.pageSize}
          pageSizeOptions={[12, 24, 36, 48, 60, 72]}
        />
        <div
          style={{
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          {isTableLoading
            ? renderTicketsAreLoadingAlert()
            : renderTicketsTiles()}
        </div>
        <StyledPagination
          style={{
            marginTop: '10px',
            textAlign: 'center',
            justifyContent: 'center',
            verticalAlign: 'center',
          }}
          showSizeChanger
          onChange={handleCurrentPageChanged}
          current={request.currentPage}
          total={tickets ? tickets.totalItems : 0}
          pageSize={request.pageSize}
          pageSizeOptions={[12, 24, 36, 48, 60, 72]}
        />
      </>
    )
  }, [
    request,
    tickets,
    isTableLoading,
    handleCurrentPageChanged,
    renderTicketsAreLoadingAlert,
    renderTicketsTiles,
  ])

  const renderTableComponent = useCallback(() => {
    if (tickets)
      return (
        <StyledTable
          loading={isTableLoading}
          dataSource={tickets.data}
          columns={columns}
          rowKey={'number'}
          onChange={handleTableChange}
          pagination={{
            total: tickets.totalItems,
            current: request.currentPage,
          }}
          bordered
          scroll={{ x: 'max-content' }}
        />
      )
  }, [tickets, isTableLoading, columns, handleTableChange, request.currentPage])

  const hotTicketsDescription = useMemo(
    () => (
      <code>
        <b>- Super Linie [709]</b>:
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.google.com/maps/d/u/2/edit?mid=1XdbcqpA5OjyXufmxC-fjVMnPOGApsgg&usp=sharing"
        >
          KLIKNIJ!
        </a>
        <br />
        <b>- Magnetyczne 5 [668]</b>:
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.google.com/maps/d/u/2/edit?mid=1QGIYZm8rmIav4W88T2N6d-7HcZ5Z1Ds&usp=sharing"
        >
          KLIKNIJ!
        </a>
        <br />
      </code>
    ),
    [],
  )

  if (!isTableLoading && error && (!tickets || !lastUpdateDate))
    return (
      <Alert
        style={{
          maxWidth: '300px',
          textAlign: 'center',
          justifyContent: 'center',
          display: 'block',
          margin: 'auto',
          marginTop: '25px',
        }}
        description={error}
        type="error"
      />
    )

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ranking zdrapek Lotto - Jaka Zdrapka</title>
        <meta
          name="description"
          content={`Które zdrapki warto kupić? Ranking najlepszych zdrapek Lotto dostępnych obecnie w sprzedaży.`}
        />
      </Helmet>
      <TicketFilters
        handleFilterChange={handleFilterChange}
        request={request}
      />
      <Radio.Group
        onChange={handleViewChanged}
        value={selectedView}
        style={{ marginBottom: 8 }}
      >
        <Radio.Button value="tiles">
          Kafelki
          <AppstoreOutlined
            style={{ marginLeft: '5px', color: 'rgba(0,0,0,.25)' }}
          />
        </Radio.Button>
        <Radio.Button value="table">
          {' '}
          Tabela
          <TableOutlined
            style={{ marginLeft: '5px', color: 'rgba(0,0,0,.25)' }}
          />
        </Radio.Button>
      </Radio.Group>
      {new Date() < new Date('2025-03-27') ? (
        <Alert
          message={
            <Text strong>
              🔥🔥🔥 Dostępne są mapy kolektur dla gorących zdrapek 🔥🔥🔥
            </Text>
          }
          style={{ maxWidth: '600px' }}
          description={hotTicketsDescription}
          type="warning"
          closable
        />
      ) : null}
      {error && (
        <Col sm={6} offset={8} style={{ textAlign: 'center' }}>
          <Alert message="Błąd pobierania danych z serwera." type="error" />
        </Col>
      )}
      {lastUpdateDate ? renderLastUpdateDate() : null}
      {selectedView === 'tiles'
        ? renderTileComponent()
        : renderTableComponent()}
    </>
  )
}

export default TicketsPage
